import { Controller } from "@hotwired/stimulus"
import createAlert from '../packs/createAlert';

export default class extends Controller {
  static targets = [ "container", "saveButton", "context" ]

  connect() {
    console.log("Hello, Alternative Names Controller here!", this.element)
    console.log(this.containerTarget)
    // console.log(this.saveButtonValue)
    console.log(this.contextTargets)
  }

  addContext(event) {
    if (event.target.classList.contains('disabled')) {
      return;
    }

    fetch(`/entity/contexts/new`).then(res => res.text()).then(partial => {
      this.containerTarget.insertAdjacentHTML( 'beforeend', partial );

      // add focus to newly created input
      this.contextTargets[this.contextTargets.length - 1].focus();
    });
  }

  removeContext(event) {
    if (event.currentTarget.dataset.confirmSwal) {
      // need to confirm first
      return;
    }

    const headers = {
      'Content-Type': 'application/json'
    }

    const csrf = document.querySelector('meta[name="csrf-token"]')
    if (csrf) {
      headers['X-CSRF-Token'] = csrf.content
    }

    const alternative_name_id = event.params.id;
    fetch(`/entity/contexts/${alternative_name_id}.json`, {
      headers,
      method: 'DELETE'
    }).then(res => {
      document.getElementById(`alternative_name_${alternative_name_id}`).remove();
      createAlert('success', 'Alternative Name removed')
    });
  }
}
