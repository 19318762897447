import { Controller } from "@hotwired/stimulus"

require("activeadmin_quill_editor")

export default class extends Controller {
  static targets = [ "content", "textarea", "form", "toolbar" ]
  static values = {
    placeholder: String
  }

  connect() {
    console.log("Hello, Quill Controller here!", this.element)
    // console.log(this.placeholderValue);

    const quill = new Quill(this.contentTarget, {
      theme: 'snow',
      placeholder: this.placeholderValue || '',
      modules: {
        toolbar: this.toolbarTarget
      },
    });

    quill.on('text-change', (delta, oldDelta, source) => {
      this.textareaTarget.value = quill.container.firstChild.innerHTML
    })

    // All of this nonsense is required because Quill insists on
    // wrapping ALL content in paragraphs, even empty content.
    // So an empty editor, when saved, would result in "<p></p>" being
    // saved to the database. This is not ideal.
    // This graphs the content, loops through the <p>'s and removes
    // them if they don't have any content.
    this.formTarget.addEventListener('submit', (event) => {
      if (quill.editor.isBlank()) {
        this.textareaTarget.value = '';
      } else {
        var ele = document.createElement('body');
        ele.innerHTML = this.textareaTarget.value;

        // if there is one empty paragraph, the textare should be treated as empty
        if(ele.querySelectorAll('p').length === 1) {
          [...ele.querySelectorAll('p')].forEach(para => {
            if (para.innerText.trim() === "") ele.removeChild(para);
          });
        }

        this.textareaTarget.value = ele.innerHTML;
      }
    });

  }

}
