import { Controller } from "@hotwired/stimulus"
import createAlert from '../packs/createAlert';

export default class extends Controller {
  static targets = [ "text", "form", "controls", "modal", "confettiContainer", "interviewSubject" ]
  static values = {
    multiple: String
  }

  connect() {
    // console.log("Hello, Stimulus from Stories Speaker Identification!", this.element)
    // console.log(this.textTarget)
    // console.log(this.textTarget.innerText);
    // console.log(this.formTarget);

    // loop through an unknown number of speakers and wrap them in a span for later replacement
    if (this.hasTextTarget && this.textTarget.innerText.includes('speaker_')) {
      for(let i = 1; i < 10; i++) {
        if (this.textTarget.innerText.includes(`speaker_${i}`)) {
          this.textTarget.innerHTML = this.textTarget.innerHTML.replaceAll(`speaker_${i}`, `<span class='speaker_${i}'>speaker_${i}</span>`)
        }
      }
    } else {
      if (this.hasControlsTarget) {
        this.controlsTarget.style.display = 'none';
      }
    }

    // for use in the FTP
    if (this.hasModalTarget) {
      if (localStorage.getItem('interviewer_speaker_identification') === 'true') {
        localStorage.removeItem('interviewer_speaker_identification');

        this.confettiContainerTarget.classList.remove('d-none');
        this.confettiContainerTarget.classList.add('d-block');

        const hoorayModal = new bootstrap.Modal(this.modalTarget);

        hoorayModal.show();

        const showConfetti = () => {
          const confetti = document.createElement("div");
          confetti.textContent = "🌭";
          confetti.classList.add("confetti");
          confetti.style.left = Math.random() * innerWidth + "px";
          this.confettiContainerTarget.appendChild(confetti);

          setTimeout(() => {
            confetti.remove();
          }, 3000);
        };

        const confettiLoop = setInterval(() => {
          showConfetti();
        }, 200);

        this.modalTarget.addEventListener('hidden.bs.modal', () => {
          clearInterval(confettiLoop);

          this.confettiContainerTarget.classList.remove('d-block');
          this.confettiContainerTarget.classList.add('d-none');
        })

      }
    }

    // if the transcript has more than 2 speakers, make dynamic TEXT fields
    if (this.multipleValue) {
      console.log('Multiple speakers detected');
      for(let i = 1; i < 10; i++) {
        if (this.textTarget.innerText.includes(`speaker_${i}`)) {
          const formControl = `<div class="mb-3">
            <label for="speaker_${i}" class="form-label">Speaker ${i}</label>
            <input type="text" class="form-control p-3 w-50" id="speaker_${i}" data-action="input->stories-speaker-identification#updateSpeaker" data-stories-speaker-identification-target="speaker" data-stories-speaker-identification-id-param="${i}">
          </div>`;
          this.controlsTarget.innerHTML += formControl;
        }
      }
    }

  }

  updateSpeaker(event) {
    console.log('Updating speaker text', event);
    let text = event.target.value;
    let id = event.params.id;
    this.replaceSpeakerLabel(id, text);
  }

  replaceSpeakerLabel(id, text) {
    console.log('Updating speaker text', id, text);
    // this.textTarget.innerHTML = newText;

    document.querySelectorAll(`.speaker_${id}`).forEach(elm => elm.innerText = text);
  }

  submitForm(event) {

    // if subject text input is present, make sure they fill it out!
    if (this.hasInterviewSubjectTarget && this.interviewSubjectTarget.value === '') {
      this.interviewSubjectTarget.classList.add('is-invalid');
      this.interviewSubjectTarget.focus();
      createAlert('danger', 'Please enter the subject of the interview');
      return;
    }

    const input = document.createElement("textarea");
    input.setAttribute("name", event.target.dataset.formElement);
    input.innerHTML = this.textTarget.innerText.replace('\r\n', '<br>');
    input.style.display = 'none';
    this.formTarget.appendChild(input);

    if (this.hasModalTarget) {
      localStorage.setItem('interviewer_speaker_identification', 'true');
    }

    this.formTarget.submit();
  }

}

